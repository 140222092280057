*, body {
  margin: 0;
  padding: 0;
}

.App {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.container {
  position: relative;
}

.container,
#interactive.viewport {
  width: 100%;
  height: 100vh;
}

#interactive.viewport canvas,
video {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
  width: 100%;
  height: 100vh;
}
